<template>
  <div class="rounded-l-3xl bg-[#194165] text-[#FFF] font-semibold px-2 py-1 cursor-pointer">
    <router-link :to="loginLink">
      <span class="bg-[#FFF] text-[#194165] text-sm rounded-full px-[4px]">
        <i class="fa fa-user"></i>
      </span>
      ログイン
    </router-link>
  </div>
</template>

<script setup>
/* LoginButton.vue */
import { ROUTER_PATH } from "@/client/const";
const loginLink = ROUTER_PATH.LOGIN;
</script>