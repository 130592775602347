<template>
  <div class="header-menu-pc content-center hidden lg:block">
    <div class="flex justify-end gap-x-2 h-auto">
      <SpaceSearchButton />
      <MyPageButton v-if="isAuthenticated" />
      <LoginButton v-else />
    </div>
  </div>
</template>

<script setup>
import SpaceSearchButton from "@/client/atoms/SpaceSearchButton.vue";
import MyPageButton from "@/client/atoms/MyPageButton.vue";
import LoginButton from "@/client/atoms/LoginButton.vue";
import { storeToRefs } from "pinia";
import { TYPE_USER } from "@/client/const";
import { getToken } from "@/client/utils/authToken";
import useAuth from "@/admin/store/auth";

const token = getToken(TYPE_USER.USER);
const store = useAuth();
const { isAuthenticated } = storeToRefs(store);

if (token) {
  store.isAuthenticated = true;
}
</script>

<style lang="scss" scoped>
@import "./HeaderMenu.scss";
</style>