import { ROUTER_PATH } from "@/client/const";
export const globalMenuList = [
  {
    id: 1,
    title: "エリアから探す",
    type: "router",
    link: `${ROUTER_PATH.CONTENT_LOCATION_HEADER}`,
    subMenu: [],
  },
  {
    id: 2,
    title: "利用用途から探す",
    type: "router",
    link: `${ROUTER_PATH.CONTENT_CASE}`,
    isOpen: false,
    subMenu: [],
  },
  {
    id: 3,
    title: "パーティー会場",
    type: "router",
    link: `${ROUTER_PATH.FOR_PARTY}`,
    subMenu: [
      {
        title: "グレイドパーク渋谷",
        type: "router",
        link: `${ROUTER_PATH.LP_GRADE_PARK_SHIBUYA}`,
      },
      {
        title: "グレイドパーク新宿",
        type: "router",
        link: `${ROUTER_PATH.LP_GLADE_PARK_SHINJUKU}`,
      },
      {
        title: "グレイドパーク表参道",
        type: "router",
        link: `${ROUTER_PATH.LP_GLADE_PARK_OMOTESANDO}`,
      },
      {
        title: "グレイドパーク恵比寿",
        type: "router",
        link: `${ROUTER_PATH.LP_EBISU_SHOW_ROOM}`,
      },
      {
        title: "グレイドパーク渋谷テラス",
        type: "router",
        link: `${ROUTER_PATH.LP_LOUNGE_R_TERRACE_SHIBUYA}`,
      },
      {
        title: "グレイドパーク南青山",
        type: "router",
        link: `${ROUTER_PATH.LP_MACE_MINAMIAOYAMA}`,
      },
      {
        title: "グレイドパーク千駄ヶ谷",
        type: "router",
        link: `${ROUTER_PATH.LP_MACE_SENDAGAYA}`,
      },
      {
        title: "グレイドパーク池袋",
        type: "router",
        link: `${ROUTER_PATH.ELLARE}`,
      },
    ],
  },
  {
    id: 4,
    title: "初めての方・用途例",
    type: "router",
    link: "",
    subMenu: [
      {
        title: "初めての方",
        type: "router",
        link: `${ROUTER_PATH.LP_HOW_TO_USE}`,
      },
      {
        title: "周年記念パーティー",
        type: "router",
        link: `${ROUTER_PATH.PARTY.SCENE001}`,
      },
      {
        title: "歓送迎会・懇親会・同窓会・女子会・ママ会・誕生日会",
        type: "router",
        link: `${ROUTER_PATH.PARTY.SCENE002}`,
      },
      {
        title: "結婚式二次会",
        type: "router",
        link: `${ROUTER_PATH.PARTY.SCENE003}`,
      },
      {
        title: "セミナー・アフターパーティー",
        type: "router",
        link: `${ROUTER_PATH.PARTY.SCENE004}`,
      },
      {
        title: "展示会",
        type: "router",
        link: `${ROUTER_PATH.PARTY.SCENE005}`,
      },
      {
        title: "撮影会・TV収録・ロケ撮影",
        type: "router",
        link: `${ROUTER_PATH.PARTY.SCENE006}`,
      },
    ],
  },
  {
    id: 5,
    title: "おすすめのオプション",
    type: "router",
    link: "",
    subMenu: [
      {
        title: "ケータリング",
        type: "router",
        link: `${ROUTER_PATH.LP_CATERING}`,
      },
      {
        title: "スペース安心保証",
        type: "router",
        link: `${ROUTER_PATH.LP_SPACE_SAFETY}`,
      },
      {
        title: "お片付けラクラクパック",
        type: "link",
        link: "https://time-sharing.jp/sharing/article-201",
      },],
  },
  {
    id: 6,
    title: "法人利用",
    type: "router",
    link: `${ROUTER_PATH.LP_CORPORATE_USE}`,
    subMenu: [],
  },
  {
    id: 7,
    title: "よくある質問",
    type: "router",
    link: `${ROUTER_PATH.USER_GUIDE.FAQ}`,
    subMenu: [],
  },
];

export const DrawerNavMenu = [
  {
    title : "スペースを探す",
    item : [
      {
        id: 1,
        title: "エリアから探す",
        type: "router",
        link: `${ROUTER_PATH.CONTENT_LOCATION_HEADER}`,
        isOpen: false,
        subMenu: [],
      },
      {
        id: 2,
        title: "利用用途から探す",
        type: "router",
        link: `${ROUTER_PATH.CONTENT_CASE}`,
        isOpen: false,
        subMenu: [],
      },
      {
        id: 3,
        title: "パーティー会場",
        type: "router",
        link: "",
        isOpen: false,
        subMenu: [
          {
            title: "グレイドパーク渋谷",
            type: "router",
            link: `${ROUTER_PATH.LP_GRADE_PARK_SHIBUYA}`,
          },
          {
            title: "グレイドパーク新宿",
            type: "router",
            link: `${ROUTER_PATH.LP_GLADE_PARK_SHINJUKU}`,
          },
          {
            title: "グレイドパーク表参道",
            type: "router",
            link: `${ROUTER_PATH.LP_GLADE_PARK_OMOTESANDO}`,
          },
          {
            title: "グレイドパーク恵比寿",
            type: "router",
            link: `${ROUTER_PATH.LP_EBISU_SHOW_ROOM}`,
          },
          {
            title: "グレイドパーク渋谷テラス",
            type: "router",
            link: `${ROUTER_PATH.LP_LOUNGE_R_TERRACE_SHIBUYA}`,
          },
          {
            title: "グレイドパーク南青山",
            type: "router",
            link: `${ROUTER_PATH.LP_MACE_MINAMIAOYAMA}`,
          },
          {
            title: "グレイドパーク千駄ヶ谷",
            type: "router",
            link: `${ROUTER_PATH.LP_MACE_SENDAGAYA}`,
          },
          {
            title: "グレイドパーク池袋",
            type: "router",
            link: `${ROUTER_PATH.ELLARE}`,
          },
        ],
      },
    ]
  },
  {
    title : "TIME SHARINGについて",
    item : [
      {
        id: 4,
        title: "初めての方・用途例",
        type: "router",
        link: "",
        isOpen: false,
        subMenu: [
          {
            title: "初めての方",
            type: "router",
            link: `${ROUTER_PATH.LP_HOW_TO_USE}`,
          },
          {
            title: "周年記念パーティー",
            type: "router",
            link: `${ROUTER_PATH.PARTY.SCENE001}`,
          },
          {
            title: "歓送迎会・懇親会・同窓会・女子会・ママ会・誕生日会",
            type: "router",
            link: `${ROUTER_PATH.PARTY.SCENE002}`,
          },
          {
            title: "結婚式二次会",
            type: "router",
            link: `${ROUTER_PATH.PARTY.SCENE003}`,
          },
          {
            title: "セミナー・アフターパーティー",
            type: "router",
            link: `${ROUTER_PATH.PARTY.SCENE004}`,
          },
          {
            title: "展示会",
            type: "router",
            link: `${ROUTER_PATH.PARTY.SCENE005}`,
          },
          {
            title: "撮影会・TV収録・ロケ撮影",
            type: "router",
            link: `${ROUTER_PATH.PARTY.SCENE006}`,
          },
        ],
      },
      {
        id: 5,
        title: "おすすめのオプション",
        link: "",
        isOpen: false,
        subMenu: [
          {
            title: "ケータリング",
            type: "router",
            link: `${ROUTER_PATH.LP_CATERING}`,
          },
          {
            title: "スペース安心保証",
            type: "router",
            link: `${ROUTER_PATH.LP_SPACE_SAFETY}`,
          },
          {
            title: "お片付けラクラクパック",
            type: "link",
            link: "https://time-sharing.jp/sharing/article-201",
          },],
      },
      {
        id: 6,
        title: "法人利用",
        type: "router",
        link: `${ROUTER_PATH.LP_CORPORATE_USE}`,
        isOpen: false,
        subMenu: [],
      },
      {
        id: 7,
        title: "よくある質問",
        type: "router",
        link: `${ROUTER_PATH.USER_GUIDE.FAQ}`,
        isOpen: false,
        subMenu: [],
      },
      {
        id: 8,
        title: "お問い合わせ",
        type: "router",
        link: `${ROUTER_PATH.CONTACT_IQUIRIES}`,
        isOpen: false,
        subMenu: [],
      },
    ]
  }
]